var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavigationConfig } from '../../utils/navigation_config';
import { DashboardIcon, UserManagementIcon, RoleManagementIcon, AdminProfileIcon, DealerManagementIcon } from './../icons';
import { useNavigate } from 'react-router-dom';
import constants from '../../config/constants';
import { useSelector } from 'react-redux';
import MarketingContentIcon from '../icons/MarketingContentIcon';
import ResubmitApplicationIcon from '../icons/ResubmitApplicationIcon';
import DealerProgramAssociationIcon from '../icons/DealerProgramAssociationIcon';
import ProgramManagementIcon from '../icons/ProgramManagementIcon';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
var drawerWidth = 230;
var openedMixin = function (theme) { return ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
}); };
var closedMixin = function (theme) {
    var _a;
    return (_a = {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: "calc(".concat(theme.spacing(7), " + 1px)")
        },
        _a[theme.breakpoints.up('sm')] = {
            width: "calc(".concat(theme.spacing(8), " + 1px)"),
        },
        _a);
};
var DrawerHeader = styled('div')(function (_a) {
    var theme = _a.theme;
    return (__assign({ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: theme.spacing(0, 1) }, theme.mixins.toolbar));
});
var Drawer = styled(MuiDrawer, { shouldForwardProp: function (prop) { return prop !== 'open'; } })(function (_a) {
    var theme = _a.theme, open = _a.open;
    return (__assign(__assign({ width: drawerWidth, flexShrink: 0, whiteSpace: 'break-spaces', boxSizing: 'border-box', zIndex: 1 }, (open && __assign(__assign({}, openedMixin(theme)), { '& .MuiDrawer-paper': openedMixin(theme) }))), (!open && __assign(__assign({}, closedMixin(theme)), { '& .MuiDrawer-paper': closedMixin(theme) }))));
});
export default function MiniDrawer(_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var userBasicInfo = useSelector(function (store) { return store.User; });
    var theme = useTheme();
    var navigate = useNavigate();
    var _b = useState(true), open = _b[0], setOpen = _b[1];
    var navConfig = NavigationConfig;
    var _c = useState([
        { title: 'Admin Management', path: navConfig.AdminManagement, isActive: false },
        { title: 'User Management', path: navConfig.UserManagement, isActive: false },
        { title: 'Portal Management', path: navConfig.DealerManagement, isActive: false, group: 'Portal Setup' },
        { title: 'Dealer Program Association', path: navConfig.DealerProgramAssocation, isActive: false, group: 'Portal Setup' },
        { title: 'Program Management', path: navConfig.ProgramManagement, isActive: false, group: 'Portal Setup' },
        { title: 'Resubmit Application', path: navConfig.ResubmitApplication, isActive: false },
        { title: 'Marketing Content', path: navConfig.MarketingConent, isActive: false },
    ]), sideMenuOptions = _c[0], setSideMenuOptions = _c[1];
    var adminAndUserItems = sideMenuOptions.filter(function (item) { return item.title === 'Admin Management' || item.title === 'User Management'; });
    var portalSetupItems = sideMenuOptions.filter(function (item) { return item.group === 'Portal Setup'; });
    var otherItems = sideMenuOptions.filter(function (item) { return item.title === 'Resubmit Application' || item.title === 'Marketing Content'; });
    var handleDrawerClose = function () {
        setOpen(!open);
    };
    var sideMenuHandler = function (selectedItem) {
        var sideMenuData = __spreadArray([], sideMenuOptions, true);
        sideMenuData.forEach(function (option) {
            if (option.title === selectedItem.title) {
                option.isActive = true;
            }
            else {
                option.isActive = false;
            }
        });
        setSideMenuOptions(sideMenuData);
        console.log('selectedItem:', selectedItem);
        navigate(selectedItem.path);
    };
    var getIcon = function (item) {
        var selectedIcon = undefined;
        switch (item.path) {
            case navConfig.Dashboard: {
                selectedIcon = React.createElement(DashboardIcon, { isDarkMode: item.isActive });
                break;
            }
            case navConfig.DealerManagement: {
                selectedIcon = React.createElement(DealerManagementIcon, { isDarkMode: item.isActive });
                break;
            }
            case navConfig.DealerProgramAssocation: {
                selectedIcon = React.createElement(DealerProgramAssociationIcon, { isDarkMode: item.isActive });
                break;
            }
            case navConfig.UserManagement: {
                selectedIcon = React.createElement(UserManagementIcon, { isDarkMode: item.isActive });
                break;
            }
            case navConfig.AdminManagement: {
                selectedIcon = React.createElement(RoleManagementIcon, { isDarkMode: item.isActive });
                break;
            }
            case navConfig.ResubmitApplication: {
                selectedIcon = React.createElement(ResubmitApplicationIcon, { isDarkMode: item.isActive, isFromSideMenu: true });
                break;
            }
            case navConfig.Support: {
                selectedIcon = React.createElement(AdminProfileIcon, { isDarkMode: item.isActive });
                break;
            }
            case navConfig.MarketingConent: {
                selectedIcon = React.createElement(MarketingContentIcon, { isDarkMode: item.isActive });
                break;
            }
            case navConfig.ProgramManagement: {
                selectedIcon = React.createElement(ProgramManagementIcon, { isDarkMode: item.isActive });
                break;
            }
        }
        return selectedIcon !== null && selectedIcon !== void 0 ? selectedIcon : React.createElement(React.Fragment, null);
    };
    useEffect(function () {
        var sideMenuData = __spreadArray([], sideMenuOptions, true);
        sideMenuData.forEach(function (item) {
            if ("/".concat(location.pathname.split('/')[1]) === item.path) {
                item.isActive = true;
            }
        });
        var filteredMenuOptions = [];
        if ((userBasicInfo === null || userBasicInfo === void 0 ? void 0 : userBasicInfo.userData.RoleType) === "ADMIN") {
            filteredMenuOptions = sideMenuData;
        }
        else if ((userBasicInfo === null || userBasicInfo === void 0 ? void 0 : userBasicInfo.userData.RoleType) === "INT_SALESREP") {
            filteredMenuOptions = sideMenuData.filter(function (option) { return option.title === 'User Management'; });
        }
        setSideMenuOptions(filteredMenuOptions);
    }, [userBasicInfo === null || userBasicInfo === void 0 ? void 0 : userBasicInfo.userData.RoleType]);
    var listItemButton = {
        minHeight: 48,
        justifyContent: open ? 'initial' : 'center',
        px: 1.5,
    };
    var listItemIcon = {
        minWidth: 0,
        mr: open ? '12px' : 'auto',
        justifyContent: 'center',
    };
    return (React.createElement(Box, { sx: { display: 'flex' } },
        React.createElement(Drawer, { variant: "permanent", open: open, sx: { boxShadow: '0px -5px 10px 0px rgb(0 0 0 / 11%)' } },
            React.createElement(Divider, null),
            React.createElement(List, { sx: { height: 'calc(100vh - 102px)', overflow: 'auto', padding: '0px' } },
                adminAndUserItems.map(function (item) { return (React.createElement(ListItem, { key: item.title, disablePadding: true, sx: {
                        display: 'block',
                        backgroundColor: item.isActive ? 'black' : '#fff'
                    }, onClick: function () { return sideMenuHandler(item); } },
                    React.createElement(ListItemButton, { sx: listItemButton },
                        React.createElement(ListItemIcon, { sx: listItemIcon }, getIcon(item)),
                        React.createElement(ListItemText, { primary: item.title, sx: { opacity: open ? 1 : 0, color: item.isActive ? '#fff' : 'primary.main' } })))); }),
                Boolean(portalSetupItems === null || portalSetupItems === void 0 ? void 0 : portalSetupItems.length) &&
                    React.createElement(Accordion, { defaultExpanded: true },
                        React.createElement(AccordionSummary, { expandIcon: React.createElement(KeyboardArrowDownOutlinedIcon, null) },
                            React.createElement(ListItem, { disablePadding: true, sx: {
                                    display: 'block',
                                    backgroundColor: '#fff'
                                } },
                                React.createElement(ListItemButton, { sx: listItemButton },
                                    React.createElement(ListItemIcon, { sx: listItemIcon },
                                        React.createElement(DashboardOutlinedIcon, null)),
                                    React.createElement(ListItemText, { primary: "Portal Setup", sx: { opacity: open ? 1 : 0, color: 'primary.main' } })))),
                        React.createElement(AccordionDetails, null, portalSetupItems.map(function (item) { return (React.createElement(ListItem, { key: item.title, disablePadding: true, sx: {
                                display: 'block',
                                backgroundColor: item.isActive ? 'black' : '#fff'
                            }, onClick: function () { return sideMenuHandler(item); } },
                            React.createElement(ListItemButton, { sx: listItemButton },
                                React.createElement(ListItemIcon, { sx: listItemIcon }, getIcon(item)),
                                React.createElement(ListItemText, { primary: item.title, sx: { opacity: open ? 1 : 0, color: item.isActive ? '#fff' : 'primary.main' } })))); }))),
                otherItems.map(function (item) { return (React.createElement(ListItem, { key: item.title, disablePadding: true, sx: {
                        display: 'block',
                        backgroundColor: item.isActive ? 'black' : '#fff'
                    }, onClick: function () { return sideMenuHandler(item); } },
                    React.createElement(ListItemButton, { sx: listItemButton },
                        React.createElement(ListItemIcon, { sx: listItemIcon }, getIcon(item)),
                        React.createElement(ListItemText, { primary: item.title, sx: { opacity: open ? 1 : 0, color: item.isActive ? '#fff' : 'primary.main' } })))); })),
            (process.env.NODE_ENV === constants.ENV.DEV || process.env.NODE_ENV === constants.ENV.QA) &&
                React.createElement(Typography, { sx: {
                        position: 'absolute', left: '0px',
                        bottom: '0px',
                        display: 'block',
                        width: '100%',
                        padding: '10px 0px',
                        textAlign: 'center',
                        margin: ' 0 auto',
                        borderTop: '1px solid #b7b7b7',
                        fontSize: '12px',
                        opacity: '0.5'
                    }, className: 'version-text' },
                    "version ",
                    process.env.APP_VERSION)),
        React.createElement(Box, { component: "main", sx: { flexGrow: 1, width: 'calc(100% - 230px)' } }, children)));
}
