import * as React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavigationConfig } from '../../utils/navigation_config';
import './../../assets/scss/login_signup.scss';
import { Box, Grid, Typography } from '@mui/material';
import { login_routes } from '../../routes/login_routes';
import Auxcaplogologin from './../../assets/images/AuxCapDashboardLogo-white.svg';
import constants from '../../config/constants';
var getUser = function (state) { return state.User; };
var LoginSignup = function (props) {
    var navConfig = NavigationConfig;
    var isLoggedIn = useSelector(function (state) { return getUser(state).isLoggedIn; });
    var roleType = useSelector(function (state) { var _a, _b; return (_b = (_a = getUser(state)) === null || _a === void 0 ? void 0 : _a.userData) === null || _b === void 0 ? void 0 : _b.RoleType; });
    var navigate = useNavigate();
    React.useEffect(function () {
        if (isLoggedIn) {
            navigate(roleType == 'ADMIN' ? navConfig.AdminManagement : navConfig.UserManagement, { replace: true });
        }
    }, [isLoggedIn, navigate]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: 'login-signup-container' },
            React.createElement(Box, { className: 'login-signup-container-left-side', sx: { display: { xs: 'none', sm: 'none', md: 'block' } } },
                React.createElement(Box, { className: "loginpage-logo", sx: {
                        p: { xs: '50px 24px 40px', sm: '150px 100px', md: '0px 50px 70px' },
                    } },
                    React.createElement("img", { src: Auxcaplogologin, alt: "logo", width: '352px', height: '112px' }))),
            React.createElement(Grid, { className: 'login-signup-container-right-side', sx: {
                    width: { xs: '100%', sm: '100%', md: '40%' },
                    p: { xs: '50px 24px 40px', sm: '150px 100px', md: '40px 60px' },
                    h: { xs: '100vh' }
                }, container: true, justifyContent: 'center', alignItems: 'center' },
                React.createElement(Box, { className: 'login-signup-components sign-up-scrollbar' },
                    React.createElement(Routes, null, login_routes.map(function (route) { return (React.createElement(Route, { key: route.path, path: "".concat(route.path), element: route.element })); }))),
                (process.env.NODE_ENV === constants.ENV.DEV ||
                    process.env.NODE_ENV === constants.ENV.QA) &&
                    React.createElement(Box, { className: 'version-wrapper' },
                        React.createElement(Typography, { color: 'gray', className: 'version-txt', fontSize: { xs: '12px', sm: '14px', md: '14px' } }, "version: ".concat(process.env.APP_VERSION)))))));
};
export default LoginSignup;
